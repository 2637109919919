<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Histórico de Caixa
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        @keypress.enter="consultar"
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 200"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <template v-slot:item.movimentacoes="{ item }">
                                <v-btn icon color="primary">
                                    <v-icon>mdi-text-search-variant</v-icon>
                                </v-btn>
                            </template>
                            <!-- <template v-slot:item.valor_inicial="{ item }">
                                <span style="color: green;">R$&nbsp;{{ formatPrice(item.valor_inicial) }}</span>
                            </template>
                            <template v-slot:item.valor_final="{ item }">
                                <span style="color: green;">R$&nbsp;{{ formatPrice(item.valor_final) }}</span>
                            </template> -->
                        </v-data-table>
                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>
                <CaixaMovimentacoes
                    v-model="caixaMovimentacoes.id"
                    :visible="caixaMovimentacoes.visible"
                    @close="onCloseCaixaMovimentacoes"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer.vue';
import Paginate from '@/components/Paginate.vue';
import CaixaMovimentacoes from './CaixaMovimentacoes.vue';

export default {
    name: 'CaixaHistorico',

    components: {
        BaseContainer,
        Paginate,
        CaixaMovimentacoes,
    },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Abertura', value: 'abertura' },
            { text: 'Fechamento', value: 'fechamento' },
            { text: 'Observação', value: 'observacao' },
            { text: 'Movimentações', value: 'movimentacoes' },
            // { text: 'Valor Inicial', value: 'valor_inicial' },
            // { text: 'Valor Final', value: 'valor_final' },
        ],
        caixaMovimentacoes: {
            visible: false,
            id: null,
        },
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            const sortBy = this.options.sortBy.length ? `&sortField=caixas.${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';
            const search = `&q=${this.search || ''}`;

            const params = `page=${this.pagination.current_page}${sortBy}${sortOrder}${search}`;

            this.$http.get(`caixa?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.loading = false;
            });
        },

        editar({ id }) {
            this.caixaMovimentacoes = {
                visible: true,
                id,
            };
        },

        onCloseCaixaMovimentacoes() {
            this.caixaMovimentacoes = {
                visible: false,
                id: null,
            };
        },
    },
}
</script>
