<template>
    <v-dialog v-model="visible" persistent max-width="800px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span>Movimentações de Caixa</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-chip outlined>
                    <h4>Valor inicial: <span style="color: green">R$ {{ formatPrice(caixa.valor_inicial) }}</span></h4>
                </v-chip>
                <v-data-table
                    fixed-header
                    :headers="headers"
                    :items="movimentacoes"
                    :mobileBreakpoint="0"
                    :loading="loading"
                    :height="$vuetify.breakpoint.height - 350"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                >
                    <template v-slot:item.tipo="{ item }">
                        <v-chip small :color="getChipColor(item.tipo) + ' white--text'">
                            {{ item.tipo }}
                        </v-chip>
                    </template>
                    <template v-slot:item.valor="{ item }">
                        <span :style="`color: ${item.valor > 0 ? 'green' : 'red'};`">
                            R$&nbsp;{{ formatPrice(item.valor) }}
                        </span>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <span>{{ formatDate(item.created_at) }}</span>
                    </template>
                </v-data-table>
                <div style="display: flex; overflow-y: auto;">
                    <div
                        v-for="(item, index) in caixa.valor_final"
                        :key="index"
                        class="my-1 mx-2"
                        style="white-space: nowrap;"
                    >
                        <b>{{ item.forma_pagamento_descricao }}</b> R$ {{ formatPrice(item.valor) }}
                    </div>
                </div>
                <div style="display: flex;"><b>Observação:</b> {{ caixa.observacao }}</div>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CaixaMovimentacoes',

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
        },
    },

    data: () => ({
        loading: false,
        caixa: {},
        movimentacoes: [],
        headers: [
            { text: 'Tipo', value: 'tipo' },
            { text: 'Pedido', value: 'pedido_id' },
            { text: 'Valor', value: 'valor' },
            { text: 'Forma Pgto', value: 'forma_pgto' },
            { text: 'Data', value: 'created_at' },
            { text: 'Observação', value: 'observacao' },
        ],
    }),

    watch: {
        value() {
            this.consultar();
        }
    },

    methods: {
        consultar() {
            if (!this.value) {
                this.caixa = {};
                this.movimentacoes = [];
                return;
            }

            this.loading = true;
            this.$http.get(`caixa/${this.value}/movimentacoes`).then(resp => {
                const { caixa, movimentacoes } = resp.data.data;

                this.caixa = caixa;
                this.movimentacoes = movimentacoes;
                this.setTotalCaixa();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        setTotalCaixa() {
            const total = this.caixa.valor_final.reduce((a, b) => a + b.valor, 0);
            this.caixa.valor_final.push({ forma_pagamento_descricao: 'TOTAL', valor: total });
        },

        getChipColor(tipo) {
            if (tipo === 'sangria') {
                return 'deep-orange accent-4';
            }

            if (tipo === 'suprimento') {
                return 'green';
            }

            return 'light-blue darken-1';
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
